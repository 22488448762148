import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component:()=>import('../views/index'),
    meta: {
      title: '路由跳转'
    }
  },
  {
    path: '/pc',
    component:()=>import('../views/pc/index'),
    children: [
      {
        path: 'home',
        component:()=>import('../views/pc/home/index'),
        meta: {
          title: '科学家的世界'
        }
      },
      {
        path: 'search',
        component:()=>import('../views/pc/search/index'),
        meta: {
          title: '搜索'
        }
      },
      {
        path: 'details',
        component:()=>import('../views/pc/details/index'),
        meta: {
          title: '详情'
        }
      },
      {
        path: 'contacts',
        component:()=>import('../views/pc/contacts/index'),
        meta: {
          title: '关于我们'
        }
      },
      {
        path: 'account',
        component:()=>import('../views/pc/account/index'),
        children: [
          {
            path: 'registered',
            component:()=>import('../views/pc/account/registered'),
            meta: {
              title: '登陆'
            }
          },
          {
            path: 'signin',
            component:()=>import('../views/pc/account/signin'),
            meta: {
              title: '注册'
            }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
